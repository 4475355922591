import { FC } from 'react';
import { BtnText, BtnWrap, OAuthLogo } from './styles';

interface GithubOAuthProps {
  onClick?: () => void;
}

export const GithubOAuthButton: FC<GithubOAuthProps> = (props) => {
  const { onClick } = props;
  return (
    <button className='gsi-material-button' onClick={onClick}>
      <div className='gsi-material-button-state'></div>
      <div className='gsi-material-button-content-wrapper'>
        <div className='gsi-material-button-icon'>
          <OAuthLogo src='https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/GitHub_Invertocat_Logo.svg/640px-GitHub_Invertocat_Logo.svg.png' />
        </div>
        <BtnText>Sign in with Github</BtnText>
      </div>
    </button>
  );
};

export default GithubOAuthButton;
