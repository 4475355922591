import { FC, useEffect, useState } from 'react';
import { PaperBox } from './styles';
import { Outlet, useNavigate } from 'react-router-dom';
import apiClient from '../../utility/apiClient';
import { JwtPayload } from './type';
import { Button } from '@mui/material';

interface UserProps {}

export const User: FC<UserProps> = (props) => {
  const navigate = useNavigate();

  const [profile, setProfile] = useState<JwtPayload | undefined>(undefined); // 儲存用戶資料
  const [error, setError] = useState<string | null>(null);

  const fetchProfile = async () => {
    try {
      const response = await apiClient.get('/protected/profile');
      // 假設 API 返回的資料結構為 { message: 'User profile', user: payload }
      setProfile(response.data.user);
      console.log(profile);
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to fetch profile');
    }
  };

  return (
    <PaperBox>
      <div id='detail'>
        <Outlet />
      </div>
      <Button onClick={fetchProfile}>Get Profile</Button>
      <p>{process.env.REACT_APP_NODE_ENV}</p>
    </PaperBox>
  );
};

export default User;
