import { FC } from "react";
import { ImgBox, LoginCard, PaperBox, Separator, Title } from './styles';
import GoogleOAuthButton from '../../components/oauth/GoogleOAuthButton';
import GithubOAuthButton from '../../components/oauth/GitHubAuthButton';

interface LoginProps {}

export const Login: FC<LoginProps> = (props) => {
  const handleClickGoogleOAuth = () => {
    const googleOAuthURL =
      'https://accounts.google.com/o/oauth2/v2/auth?' +
      new URLSearchParams({
        client_id:
          '138321105345-th4us33ufc4mg647sti457719igsa6ke.apps.googleusercontent.com',
        redirect_uri: 'https://api.imaker-studio.com/auth/google/callback',
        response_type: 'code',
        scope: 'profile email',
        access_type: 'offline',
        prompt: 'select_account',
      }).toString();

    // 在主頁面直接觸發 Google OAuth 流程
    window.location.href = googleOAuthURL;
  };

  return (
    <PaperBox>
      <LoginCard>
        <ImgBox src='/logo_fluxnote.PNG' />
        <Title variant='h2'>Log in</Title>
        <Separator />
        <GoogleOAuthButton onClick={handleClickGoogleOAuth} />
        <GithubOAuthButton />
      </LoginCard>
    </PaperBox>
  );
};

export default Login;
