import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import apiClient from '../../utility/apiClient';

interface AuthCallBackProps {}

export const AuthCallBack: FC<AuthCallBackProps> = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const processToken = async () => {
      const code = searchParams.get('code'); // 獲取 one-time code

      if (!code) {
        // 如果缺少 code，重定向到首頁
        navigate('/');
        return;
      }

      try {
        // 交換 one-time code 獲取 Refresh Token
        const response = await apiClient.post(
          '/auth/exchange-code',
          { code },
          { withCredentials: true }
        );

        if (response.status === 200) {
          // 成功後使用 Refresh Token 獲取 Access Token
          await apiClient.post('/auth/refresh', {}, { withCredentials: true });

          // 成功後跳轉到主應用頁面
          navigate('/app');
        }
      } catch (error) {
        console.error('Error during token exchange or refresh:', error);
        // 如果發生錯誤，跳轉到登錄頁
        navigate('/login');
      }
    };

    processToken();
  }, [searchParams, navigate]);

  return <div>Processing...</div>; // 加載中的簡單 UI
};

export default AuthCallBack;
