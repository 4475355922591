import React from 'react';
import { ThemeProvider } from '@mui/material';
import { myTheme } from './theme';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import { Root } from './pages/root';
import { ErrorPage } from './pages/error-page';
import Posts from './pages/posts/posts';
import { HomePage } from './pages/home';
import { Login } from './pages/login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { User } from './pages/user';
import { AuthCallBack } from './pages/authCallBack';
import { BioPage } from './pages/bioPage';
import { NewPostPage } from './pages/newPostPage';
import PrivateRoute from './router/privateRoute';
import { useAuth } from './hooks/useAuth';

const GOOGLE_OAUTH_CLIENT_ID = "138321105345-th4us33ufc4mg647sti457719igsa6ke.apps.googleusercontent.com";

const postsLoader = async ({ params }: any) => {
  const { postId } = params;
  console.log("params.contactId", params);
  return postId;
};

function App() {
  const { isAuthenticated } = useAuth();

  const routes: RouteObject[] = [
    {
      path: '/docs',
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: 'posts/:postId',
          element: <Posts />,
          loader: postsLoader,
        },
      ],
    },
    {
      path: '/',
      element: <HomePage />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/login',
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/app',
      element: (
        <PrivateRoute
          isAuthenticated={isAuthenticated}
          authenticationPath={'/login'}
          route={<User />}
        />
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: 'new',
          element: <NewPostPage />,
        },
      ],
    },
    {
      path: '/auth/callback',
      element: <AuthCallBack />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/founder',
      element: <BioPage />,
      errorElement: <ErrorPage />,
    },
  ];

  const router = createBrowserRouter(routes);

  return (
    <ThemeProvider theme={myTheme}>
      <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
        <RouterProvider router={router} />
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;