import axios from 'axios';

// 建立 Axios 實例
const apiClient = axios.create({
  baseURL: 'https://api.imaker-studio.com', // 後端 API 的基礎 URL
  withCredentials: true, // 允許攜帶 HTTP-only Cookie
  headers: {
    'Content-Type': 'application/json',
  },
});

// 設定攔截器
apiClient.interceptors.response.use(
  (response) => {
    // 如果響應成功，直接返回
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // 如果是 401 Unauthorized，嘗試使用 Refresh Token 更新 Access Token
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // 避免重試時進入無限循環

      try {
        // 發送請求到刷新 Token 的端點
        await axios.post(
          'https://api.imaker-studio.com/auth/refresh',
          {},
          {
            withCredentials: true, // 攜帶 Refresh Token 的 Cookie
          }
        );

        // 刷新成功後，重新發送原始請求
        return apiClient(originalRequest);
      } catch (refreshError) {
        console.error('Refresh token failed:', refreshError);
        // 如果刷新失敗，則跳轉到登錄頁或處理其他邏輯
        window.location.href = '/login'; // 或根據你的應用邏輯處理
      }
    }

    return Promise.reject(error); // 其他錯誤直接拋出
  }
);

export default apiClient;
